import styled, { css } from 'styled-components';

import {
  RadioButtonField as FormRadioButton,
  TextField as TextFieldForm,
  Button as FormButton,
} from '@components/form';
import { media } from '@theme/media';
import { colonSuffix, paragraphTextStyles } from '@components/blocks/blocks.styles';
import { CONTAINER_PADDING, NARROW_CONTAINER_WIDTH } from '@components/blocks';
import { FONT_SIZE, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

export const Container = styled.div`
  margin: 0 20px;

  ${media.tablet} {
    margin: 0 auto;
    width: calc(${NARROW_CONTAINER_WIDTH}px + ${OFFSET.XL});
  }

  ${media.desktop} {
    margin: ${OFFSET.M} 0 0;
    width: auto;
    display: grid;
    grid-template-columns:
      calc((100% - ${NARROW_CONTAINER_WIDTH}px - ${CONTAINER_PADDING}px * 3) / 2)
      calc(${NARROW_CONTAINER_WIDTH}px + ${CONTAINER_PADDING}px * 3)
      auto;
  }
`;

export const Title = styled.h3`
  ${paragraphTextStyles};
  ${colonSuffix};
  margin-top: ${OFFSET.M};
  margin-bottom: ${OFFSET.XS};
  position: relative;
  hyphens: auto;

  ${media.tablet} {
    margin-bottom: ${OFFSET.S};
  }

  ${media.desktop} {
    margin: 0;
    padding-left: ${CONTAINER_PADDING * 0.9}px;
    padding-right: ${CONTAINER_PADDING * 0.9}px;
  }
`;

const newsletterFormFontStyles = css`
  ${typography({})};
`;

export const Form = styled.form``;

export const TermsConditions = styled.div``;

export const CheckboxField = styled(FormRadioButton)`
  color: currentColor;

  label {
    div {
      ${newsletterFormFontStyles};
    }
  }
  input {
    width: 18px;
    height: 18px;
    border-radius: 50%;

    &:after {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }
`;

export const TextField = styled(TextFieldForm)`
  color: currentColor;
  width: 420px;
  label {
    ${newsletterFormFontStyles};
  }
`;

export const Button = styled(FormButton)<{ backgroundColor: string; textColor: string }>`
  ${newsletterFormFontStyles};

  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
  border-color: ${({ textColor }) => textColor};

  &:hover {
    background-color: ${({ textColor }) => textColor};
    color: ${({ backgroundColor }) => backgroundColor};
  }
`;

export const InformationText = styled.p`
  margin: 0 0 ${OFFSET.S};
  ${typography({})};

  ${media.mobile} {
    ${typography({ size: FONT_SIZE.M })};
  }
`;
