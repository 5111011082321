import React, { useMemo, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { always } from 'ramda';
import { AnimatePresence } from 'framer-motion';

import { BUTTON_SIZE, Error, TextInput, formMessages } from '@components/form';
import { useYupValidationResolver } from '@hooks/useYup';
import api from '@services/nextAPI';
import { formatIntlLink } from '@utils/helpers';
import reportError from '@utils/reportError';
import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { MainAnimateContainer } from '@components/mainAnimateContainer';
import { DEFAULT_BACKGROUND_COLOR, DEFAULT_TEXT_COLOR, ROUTE } from '@utils/constants';
import { BLOCK_TYPE } from '@components/blocks';
import pintrk, { PINTEREST_EVENTS } from '@utils/pinterestTag';
import { useTrackConversions } from '@hooks/useTrackConversions';
import { CONVERSIONS_EVENTS, CONVERSIONS_STATUS } from '@definitions/conversions.types';
import { useBreakpoint } from '@hooks/useBreakpoint';

import {
  Form,
  TermsConditions,
  CheckboxField,
  TextField,
  Button,
  InformationText,
  Container,
  Title,
} from './newsletterBlock.styles';
import { EMAIL_FIELD_NAME, NEWSLETTER_TERMS_NAME, FORM_TEST_ID } from './newsletterBlock.constants';

export interface FormProps {
  email: string;
  termsConditions: string;
}

export interface NewsletterBlockProps {
  infoText: string;
  tag: string;
  successInformation: string;
  title: string;
  textColor?: string;
  backgroundColor?: string;
}

export const NewsletterBlock = ({
  infoText,
  tag,
  successInformation,
  title,
  textColor = DEFAULT_TEXT_COLOR,
  backgroundColor = DEFAULT_BACKGROUND_COLOR,
}: NewsletterBlockProps) => {
  const intl = useIntl();
  const { isMobile } = useBreakpoint();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { sendConversion } = useTrackConversions();
  const emailPlaceholder = intl.formatMessage({
    id: 'newsletterBlock.emailPlaceholder',
    defaultMessage: 'Email Address:',
  });
  const termsCopy = {
    id: 'newsletterBlock.terms',
    defaultMessage: 'I have read, understood and accept the <terms>terms & conditions</terms>',
  };
  const termsRequired = intl.formatMessage({
    id: 'newsletterBlock.termsRequired',
    defaultMessage: 'You need to accept terms and conditions.',
  });
  const emailError = intl.formatMessage({
    id: 'newsletterBlock.emailError',
    defaultMessage: 'Please enter a valid email address.',
  });

  const validationSchema = useMemo(
    () =>
      yup.object({
        [EMAIL_FIELD_NAME]: yup.string().email(emailError).required(emailError),
        [NEWSLETTER_TERMS_NAME]: yup.bool().oneOf([true], termsRequired).required(termsRequired),
      }),
    []
  );
  const resolver = useYupValidationResolver(validationSchema);

  const { register, handleSubmit, control } = useForm<FormProps>({ resolver });
  const handleNewsletterSubmit = async ({ email }: FormProps) => {
    try {
      setLoading(true);
      setError(false);
      await api.post('email/subscribe', { email, tags: [tag] });
      setSuccess(true);
      await sendConversion({
        eventType: CONVERSIONS_EVENTS.COMPLETE_REGISTRATION,
        status: CONVERSIONS_STATUS.NEWSLETTER,
      });
      pintrk.event(PINTEREST_EVENTS.SIGNUP, { lead_type: 'Newsletter' });
    } catch (e) {
      setError(true);
      reportError(e);
    } finally {
      setLoading(false);
    }
  };
  const { errors } = useFormState({ control });

  const renderError = renderWhenTrue(
    always(
      <Error>
        <FormattedMessage {...formMessages.generalError} />
      </Error>
    )
  );

  const renderForm = renderWhenTrueOtherwise(
    always(
      <MainAnimateContainer key="form">
        <Form data-testid={FORM_TEST_ID} onSubmit={handleSubmit(handleNewsletterSubmit)}>
          <InformationText>{infoText}</InformationText>
          <div style={{ display: 'flex', marginTop: 30 }}>
            <TextInput
              type="email"
              register={register(EMAIL_FIELD_NAME)}
              error={errors[EMAIL_FIELD_NAME]}
              placeholder={emailPlaceholder}
            />
            <Button
              style={{ marginLeft: 20, height: 38, width: '20%' }}
              type="submit"
              disabled={loading}
              fullWidth={true}
              size={BUTTON_SIZE.L}
              textColor={textColor}
              backgroundColor={backgroundColor}
            >
              <FormattedMessage id="newsletterBlock.submitEmail" defaultMessage="Sign Up" />
            </Button>
          </div>
          {renderError(error)}

          <TermsConditions>
            <CheckboxField
              register={register(NEWSLETTER_TERMS_NAME)}
              error={errors[NEWSLETTER_TERMS_NAME]}
              label={intl.formatMessage(termsCopy, {
                terms: formatIntlLink(ROUTE.TERMS_AND_CONDITIONS),
              })}
            />
          </TermsConditions>
        </Form>
      </MainAnimateContainer>
    ),
    always(
      <MainAnimateContainer key="successMessage">
        <InformationText>{successInformation}</InformationText>
      </MainAnimateContainer>
    )
  );

  const renderMobileForm = renderWhenTrueOtherwise(
    always(
      <MainAnimateContainer key="form">
        <Form data-testid={FORM_TEST_ID} onSubmit={handleSubmit(handleNewsletterSubmit)}>
          <InformationText>{infoText}</InformationText>
          <TextInput
            type="email"
            register={register(EMAIL_FIELD_NAME)}
            error={errors[EMAIL_FIELD_NAME]}
            placeholder={emailPlaceholder}
          />
          <TermsConditions>
            <CheckboxField
              register={register(NEWSLETTER_TERMS_NAME)}
              error={errors[NEWSLETTER_TERMS_NAME]}
              label={intl.formatMessage(termsCopy, {
                terms: formatIntlLink(ROUTE.TERMS_AND_CONDITIONS),
              })}
            />
          </TermsConditions>
          {renderError(error)}
          <div style={{ marginTop: 50 }}>
            <Button
              type="submit"
              disabled={loading}
              fullWidth={true}
              size={BUTTON_SIZE.L}
              textColor={textColor}
              backgroundColor={backgroundColor}
            >
              <FormattedMessage id="newsletterBlock.submitEmail" defaultMessage="Sign Up" />
            </Button>
          </div>
        </Form>
      </MainAnimateContainer>
    ),
    always(
      <MainAnimateContainer key="successMessage">
        <InformationText>{successInformation}</InformationText>
      </MainAnimateContainer>
    )
  );

  return (
    <Container data-testid={BLOCK_TYPE.NEWSLETTER_BLOCK}>
      <Title>{title}</Title>
      <AnimatePresence initial={false} exitBeforeEnter>
        {isMobile ? renderMobileForm(!success) : renderForm(!success)}
      </AnimatePresence>
    </Container>
  );
};
