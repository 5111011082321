import React from 'react';

import { Artwork } from '@definitions/artworks.types';
import { BLOCK_TYPE } from '@components/blocks/blocks.constants';
import { ArtworkList } from '@components/artworkList';

import { Container, Title, ProductsContainer, imageStyles } from './productsWithBasicTitle.styles';

export interface ProductsWithBasicTitleProps {
  title: string;
  products: Artwork[];
  textColor?: string;
  backgroundColor?: string;
  shoppable?: boolean;
  accessToken?: string;
}

export const ProductsWithBasicTitle = ({
  title,
  products,
  textColor,
  backgroundColor,
  shoppable,
  accessToken,
}: ProductsWithBasicTitleProps) => (
  <Container data-testid={BLOCK_TYPE.PRODUCTS_WITH_BASIC_TITLE}>
    <Title>{title}</Title>
    <ProductsContainer>
      <ArtworkList
        artworks={products}
        imageStyles={imageStyles}
        textColor={textColor}
        backgroundColor={backgroundColor}
        shoppable={shoppable}
        accessToken={accessToken}
      />
    </ProductsContainer>
  </Container>
);
