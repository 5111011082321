import React, { Fragment } from 'react';
import NextLink from 'next/link';

import { Link as LinkTypes } from '@definitions/introText.types';
import { renderWhenTrueOtherwise } from '@utils/rendering';
import { DEFAULT_TEXT_COLOR } from '@utils/constants';

import { Container, ArrowRight, Text, Link, ExternalLink } from './readMoreButton.styles';

export interface ReadMoreButtonProps extends LinkTypes {
  color?: string;
  className?: string;
  tabIndex?: number;
  ariaLabel?: string;
}

export const ReadMoreButton = ({
  type,
  urlPath,
  slug,
  displayText,
  color = DEFAULT_TEXT_COLOR,
  className,
  ariaLabel,
  tabIndex = 0,
}: ReadMoreButtonProps) => {
  const renderLinkContent = () => (
    <Fragment>
      <ArrowRight />
      <Text>{displayText}</Text>
    </Fragment>
  );

  const renderLink = renderWhenTrueOtherwise(
    () => (
      <ExternalLink href={urlPath} target="_blank" tabIndex={tabIndex}>
        {renderLinkContent()}
      </ExternalLink>
    ),
    () => (
      <a
        onClick={() => {
          window.history.pushState({}, '', `/${slug}`);
        }}
        href={`/${slug}`}
      >
        <Link tabIndex={tabIndex} aria-label={ariaLabel}>
          {renderLinkContent()}
        </Link>
      </a>
    )
  );

  return (
    <Container className={className} color={color}>
      {renderLink(type && type === 'external')}
    </Container>
  );
};
