import styled, { css } from 'styled-components';

import { media } from '@theme/media';
import { LETTER_SPACING, typography } from '@theme/font';
import { OFFSET } from '@theme/helpers';

export const Container = styled.div``;

export const Title = styled.div`
  ${typography({ spacing: LETTER_SPACING.M })};
  text-transform: uppercase;
  text-align: center;
  margin: ${OFFSET.M} 0 ${OFFSET.L};
`;

export const ProductsContainer = styled.div`
  margin: 0;
  padding: 0 ${OFFSET.S};
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${OFFSET.S};

  ${media.mobile} {
    grid-template-columns: repeat(3, 1fr);
    gap: ${OFFSET.M};
  }

  ${media.tablet} {
    grid-template-columns: repeat(4, 1fr);
    padding: 0 ${OFFSET.M};
  }
`;

export const imageStyles = css`
  height: calc(50vw - 40px);
  object-fit: contain;
  max-width: 100%;

  ${media.tablet} {
    height: calc(25vw - 40px - 15px);
  }
`;
